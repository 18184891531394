.custom-toastr {
  width: fit-content !important;
  margin: auto !important;
  padding: 18px 10px !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
.custom-toastr:global(.toast-success) {
  background-color: #dfece9 !important;
  border: 1px solid #127960 !important;
}

:global(#toast-container) > .custom-toastr:global(.toast-success) {
  background-image: url(../Images/Trade/demand-quote-circle-tick.svg) !important;
  background-position-x: 10px;
}

:global(#toast-container) > .custom-toastr:global(.toast-error) {
  /* background-image: url(../Images/Trade/demand-quote-circle-tick.svg) !important; */
  background-position-x: 10px;
}

.custom-toastr:global(.toast-warning) {
  background-color: #fdf0d9 !important;
  border: 1px solid #ae750a !important;
}

.custom-toastr:global(.toast-error) {
  background-color: #fbd9d7 !important;
  border: 1px solid #d42015 !important;
}

:global(#toast-container) > .custom-toastr:global(.toast-warning) {
  background-image: url(../Images/Trade/supply-quote-circle-tick.svg) !important;
  background-position-x: 10px;
}

:global(#toast-container) > .custom-toastr:global(.toast-error) {
  background-image: url(../Images/Trade/ErrorIconToast.svg) !important;
  background-position-x: 10px;
}

:global(#toast-container)
  > .custom-toastr:global(.toast-info)
  > :global(.toast-message) {
  padding-left: 30px !important;
}

.custom-toastr > :global(.toast-message) {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  padding-left: 24px;
  /* Accent/4 */
}
.custom-toastr:global(.toast-success) > :global(.toast-message) {
  color: #127960;
}

.custom-toastr:global(.toast-warning) > :global(.toast-message) {
  color: #744e07;
}
.custom-toastr:global(.toast-error) > :global(.toast-message) {
  color: #d42015;
}
.toast-top-center1 {
  top: 30px;
  right: 0;
  width: 100%;
}

.email-otp-toastr {
  width: fit-content !important;
  margin: auto !important;
  padding: 18px 16px !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
.email-otp-toastr:global(.toast-success) {
  background-color: #ffffff !important;
}

:global(#toast-container) > .email-otp-toastr:global(.toast-success) {
  background-image: url(../Images/CommonImages/envelop.svg) !important;
  background-position-x: 16px;
}

.email-otp-toastr > :global(.toast-message) {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;

  /* Accent/3 */

  padding-left: 24px;

  /* Accent/4 */
}
.email-otp-toastr:global(.toast-success) > :global(.toast-message) {
  color: #0e5b48;
}

.toast-top-center2 {
  top: 4px;
  right: 0;
  width: 100%;
}
